// import Layout from '../containers/layout'
import React from 'react'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import style from "../components/welcome.module.css";
import Icon from "../components/icons";
import Layout from "../containers/layout";
import styles from "../components/404.module.css";

const NotFoundPage = () => (
  <>
    <Layout noFooter={true}  site='Au'>
      <div className={style.pageWrapper}>
        <SEO title='The Disruption&reg; Company' location={'Australia'}/>
        {/*<div className={styles.logo}>*/}
        {/*  <a href='/'>*/}
        {/*  <Icon symbol='HeaderLogoWhite'/>*/}
        {/*  </a>*/}
        {/*</div>*/}
        <div className={styles.content}>
            <a href='/' className={styles.logo}>
            <Icon symbol='HeaderLogoWhite'/>
            </a>
          <h1 className={styles.heading}>OPPS! PAGE NOT FOUND</h1>
          <p>Sorry, the page you're looking for doesn't exist.</p>
          <a className={styles.link} href='/'>
            RETURN HOME<Icon symbol='arrowTopLeft'/>
          </a>
        </div>
      </div>
    </Layout>
  </>
)

export default NotFoundPage
